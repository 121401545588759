.login-page-container {
  display: flex;
  justify-content: center;
  /*  position: fixed; */
  /*  top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  width: 100% !important;
  .login-form {
    width: 100% !important;
    /* .login-form-button {
      background: #00b7ae !important;
      border-color: #00b7ae !important;
      opacity: 1;
    }
    .login-form-button:hover {
      opacity: 0.7;
    } */
  }
}
.login-page-icon-container {
  display: flex;
  justify-content: center;
  padding: 1em;
  .login-page-icon {
    width: 60%;
    height: 60%;
    user-select: none;
  }
}

/* body {
  background: #ffffff !important;
} */

/* .ant-tabs-tab-active {
  .ant-tabs-tab-btn {
    color: #00b7ae !important;
    font-weight: 500;
  }
}

.ant-tabs-ink-bar {
  background: #00b7ae !important;
}

.ant-tabs-tab:hover {
  color: #00a49a !important;
}

.ant-input-affix-wrapper:hover {
  border-color: #00b7ae !important;
} */

.ant-layout-content {
  min-height: fit-content !important;
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.sider {
  min-width: 240px !important;
  max-width: 240px !important;
}

.logo-container {
  margin: 16px;
  display: flex;
  justify-content: center;
  align-content: center;
  .logo-icon {
    user-select: none;
    display: block;
    max-width: 200px;
    max-height: 150px;
    width: auto;
    height: auto;
    align-self: flex-start;
  }
  .logo-icon-collapsed {
    user-select: none;
    display: block;
    max-width: 62px !important;
    max-height: 32px !important;
    width: auto;
    height: auto;
    align-self: flex-start;
  }
  .logo-icon-tablog {
    display: block;
    max-width: 190px;
    max-height: 95px;
    width: auto;
    height: auto;
  }
  .logo-icon-tablog-collapsed {
    height: 10px;
  }
}
.logo-container-collapsed {
  margin: 10px !important;
}

.site-layout .site-layout-background {
  background: #fff;
}

.link-color {
  color: #fff !important;
}

body {
  background: #ffffff;
}

.ant-tag {
  .anticon-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px;
  }
}